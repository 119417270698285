<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <div v-if="!isLoading && serviceCategory">
      <h1 v-if="id">Edit Service Category</h1>
      <h1 v-else>Create Service Category</h1>
      <p>Categories can be assigned to the services you provide.</p>
      <hr>
      <b-field class="modal-label" label="Name">
        <b-input v-model="serviceCategory.name" type="text"/>
      </b-field>
      <b-field class="modal-label" label="Description">
        <b-input v-model="serviceCategory.description" type="textarea"/>
      </b-field>
      <hr>
      <div class="level is-mobile">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="level-item">
            <a class="button is-light" @click="cancel">Cancel</a>
          </div>
          <div class="level-item">
            <button class="button is-primary" @click="save">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'ServiceCategoryEdit',
  data () {
    return {
      breadcrumbs: [
        { name: 'Settings', route: 'settings-home' },
        { name: 'Service Categories', route: 'settings-service-categories' },
        { name: 'Edit Service Category', route: null }
      ],
      isLoading: false,
      serviceCategory: {
        name: '',
        description: ''
      }
    }
  },
  methods: {
    cancel () {
      this.$router.push({ name: 'settings-home' })
    },
    reloadServiceCategory () {
      if (this.id) {
        this.isLoading = true
        this.$store.dispatch('settings/fetchServiceCategory', this.id)
          .then((serviceCategory) => {
            this.serviceCategory = serviceCategory
            this.isLoading = false
          })
      }
    },
    save () {
      this.$store.dispatch('settings/saveServiceCategory', this.serviceCategory)
        .then((response) => {
          this.$buefy.toast.open({
            message: 'Service category saved.',
            type: 'is-success'
          })
          this.$router.push({ name: 'settings-service-categories' })
        })
        .catch(err => {
          const errors = err.response.data.errors
          if (errors) {
            Object.keys(errors).forEach((errorKey) => {
              this.$buefy.toast.open({
                message: errors[errorKey][0],
                type: 'is-danger',
                duration: 5000,
                queue: false
              })
            })
          } else {
            this.$buefy.toast.open({
              message: `Error: ${err.message}`,
              type: 'is-danger',
              duration: 4000,
              queue: false
            })
          }
        })
    }
  },
  mounted () {
    this.reloadServiceCategory()
  },
  components: { Breadcrumbs },
  props: ['id']
}
</script>
